<template>
	<Message severity="warn" v-if="user?.userweb_status?.is_industry_found == 'n'">You haven’t selected an industry yet. To proceed, please visit the Industry page and complete the required information.
		<router-link to="/industry" class="text-blue-500"><u>Go to The Industry Page</u></router-link>
	</Message>
</template>

<script>

import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters("auth", ["user"]),
	},
}
</script>