<template>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<Message severity="info">
				<h3>Package Details: <u> {{user?.userweb_status?.package_name}} Plan</u></h3>
				<p>
					You have selected the Bronze Plan, which includes the following features and slots:
				</p>
				<ul>
					<li><strong>Project Creation Slots:</strong> {{user?.userweb_status?.max_project}} projects</li>
					<li><strong>Industry Creation Slots:</strong> {{user?.userweb_status?.max_industries}} industries</li>
					<li><strong>Cluster Creation Slots:</strong> {{user?.userweb_status?.max_slot_cluster}} Cluster</li>
					<li><strong>Maximal Outlet Distance:</strong> {{user?.userweb_status?.max_outlet_distance}} km</li>
				</ul>
				<p>
					<strong>Subscription Start Date:</strong> {{formatDate(user?.userweb_status?.package_start)}} <br>
					<strong>Subscription Expiry Date:</strong> {{formatDate(user?.userweb_status?.package_end)}}
				</p>
				<p>
					To maximize your subscription benefits, ensure you use these slots effectively. 
					For further details or to upgrade your plan, visit the payment page.
				</p>
			</Message>
		</div>
	</div>

</template>

<script>

import moment from 'moment';
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters("auth", ["user"]),
	},
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
    },
}
</script>